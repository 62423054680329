var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"asicomp media cursor-pointer",attrs:{"tag":"article","to":{
    name: 'training_uuid_assignment_submission',
    params: {
      uuid: _vm.trainingUUID,
      id: _vm.trainingItemUUID,
      submissionId: _vm.item.id,
    }
  }}},[_c('div',{staticClass:"media-content"},[_c('header',{staticClass:"flex-between-center"},[_c('h2',{staticClass:"title is-5 mb-0"},[_c('router-link',{staticClass:"color-inherit",attrs:{"to":{
            name: 'training_uuid_assignment_submission',
            params: {
              uuid: _vm.trainingUUID,
              id: _vm.trainingItemUUID,
              submissionId: _vm.item.id,
            }
          }}},[_vm._v(" "+_vm._s(_vm.item.customer.firstname)+" "+_vm._s(_vm.item.customer.lastname)+" ")])],1),_c('div',[(_vm.item.status != 'PENDING_CUSTOMER')?_c('time',{attrs:{"datetime":_vm.item.customer_submission_at}},[_vm._v(" Soumis le "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.item.customer_submission_at),'DD/MM/YY [à] HH:mm'))+" ")]):_vm._e(),(_vm.item.status == 'PENDING_CUSTOMER')?_c('time',{attrs:{"datetime":_vm.item.created_at}},[_vm._v(" Commencé le "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.item.created_at),'DD/MM/YY [à] HH:mm'))+" ")]):_vm._e(),(_vm.item.status == 'COMPLETED')?_c('b-tooltip',{attrs:{"position":"is-left"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Corrigé le "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.item.teacher_validation_at),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}],null,false,1854343510)},[_c('b-icon',{attrs:{"type":"is-primary","icon":"check-circle"}})],1):_vm._e()],1)]),_c('p',{staticClass:"mt-3"},[_c('span',[_c('b-icon',{attrs:{"pack":"far","icon":"file"}}),_vm._v(" "+_vm._s(_vm.pathsLength)+" document"+_vm._s(_vm.pathsLength > 1 ? 's' : '')+" ")],1),_c('span',{staticClass:"ml-3"},[_c('b-icon',{attrs:{"pack":"far","icon":"comment"}}),_vm._v(" "+_vm._s(_vm.item.discussions_count)+" message"+_vm._s(_vm.item.discussions_count > 1 ? 's' : '')+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }