<script>
export default {
  props: {
    trainingUUID: {
      type: [String, Number],
      required: true,
    },
    trainingItemUUID: {
      type: [String, Number],
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pathsLength() {
      return this.item.paths ? this.item.paths.length : 0;
    },
  },
};
</script>

<template>
  <router-link
    tag="article"
    class="asicomp media cursor-pointer"
    :to="{
      name: 'training_uuid_assignment_submission',
      params: {
        uuid: trainingUUID,
        id: trainingItemUUID,
        submissionId: item.id,
      }
    }"
  >
    <div class="media-content">
      <header class="flex-between-center">
        <h2 class="title is-5 mb-0">
          <router-link
            class="color-inherit"
            :to="{
              name: 'training_uuid_assignment_submission',
              params: {
                uuid: trainingUUID,
                id: trainingItemUUID,
                submissionId: item.id,
              }
            }"
          >
            {{ item.customer.firstname }}
            {{ item.customer.lastname }}
          </router-link>
        </h2>
        <div>
          <time
            v-if="item.status != 'PENDING_CUSTOMER'"
            :datetime="item.customer_submission_at"
          >
            Soumis le {{ item.customer_submission_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </time>
          <time
            v-if="item.status == 'PENDING_CUSTOMER'"
            :datetime="item.created_at"
          >
            Commencé le {{ item.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </time>
          <b-tooltip v-if="item.status == 'COMPLETED'" position="is-left">
            <template #content>
              Corrigé le {{ item.teacher_validation_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
            </template>
            <b-icon type="is-primary" icon="check-circle" />
          </b-tooltip>
        </div>
      </header>
      <p class="mt-3">
        <span>
          <b-icon pack="far" icon="file" />
          {{ pathsLength }}
          document{{ pathsLength > 1 ? 's' : '' }}
        </span>
        <span class="ml-3">
          <b-icon pack="far" icon="comment" />
          {{ item.discussions_count }}
          message{{ item.discussions_count > 1 ? 's' : '' }}
        </span>
      </p>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.asicomp {
  &:hover .title {
    text-decoration: underline;
  }
}
</style>
