<script>
import APITrainings from '@app/services/API/Trainings';
import AssignmentSubmissionItem from '@app/components/AssignmentSubmissionItem.vue';

export default {
  components: {
    AssignmentSubmissionItem,
  },
  data: () => ({
    isFetching: false,
    page: 1,
    submissions: {
      data: null,
    },
    filterStatus: 'PENDING_TEACHER',
  }),
  methods: {
    fetchSubmissions(status, page = 1) {
      const params = { page };

      this.page = page;

      if (status) {
        params.status = status;
      }

      this.isFetching = true;
      this.filterStatus = status;
      const { uuid, id } = this.$route.params;

      APITrainings.getItemAssignmentSubmissions(uuid, id, params)
        .then((submissions) => (this.submissions = submissions))
        .finally(() => (this.isFetching = false));
    },
  },
  created() {
    this.fetchSubmissions(this.filterStatus);
  },
};
</script>

<template>
  <b-modal
    active
    scroll="keep"
    :canCancel="['escape', 'outside']"
    @close="$router.push({
      name: 'training',
      params: {uuid: $route.params.uuid}
    })"
  >
    <AppCardModal>
      <template #title>
        Liste des soumissions
      </template>
      <template #body>
        <div class="tabs mb-5">
          <ul>
            <li
              :class="{'is-active': filterStatus === 'PENDING_TEACHER'}"
              @click.prevent="fetchSubmissions('PENDING_TEACHER')"
            >
              <a href="#">À corriger</a>
            </li>
            <li
              :class="{'is-active': filterStatus === 'PENDING_CUSTOMER'}"
              @click.prevent="fetchSubmissions('PENDING_CUSTOMER')"
            >
              <a href="#">En cours</a>
            </li>
            <li
              :class="{'is-active': filterStatus === 'COMPLETED'}"
              @click.prevent="fetchSubmissions('COMPLETED')"
            >
              <a href="#">Corrigées</a>
            </li>
          </ul>
        </div>

        <b-skeleton v-if="isFetching && !submissions.data" height="120" />
        <template v-else>
          <b-loading v-if="isFetching" is-full-page active />
          <p v-if="submissions.data.length <= 0" class="box">
            Aucune soumission.
          </p>
          <template v-else>
            <AssignmentSubmissionItem
              v-for="one in submissions.data"
              :key="one.id"
              :trainingUUID="$route.params.uuid"
              :trainingItemUUID="$route.params.id"
              :item="one"
            />
            <div v-if="submissions.meta && submissions.meta.last_page > 1" class="mt-8">
              <b-pagination
                :total="submissions.meta.total"
                :per-page="submissions.meta.per_page"
                aria-next-label="Page suivante"
                aria-previous-label="Page précédente"
                aria-page-label="Page"
                aria-current-label="Page actuelle"
                v-model="page"
                @change="fetchSubmissions(filterStatus, $event)"
              />
            </div>
          </template>
        </template>
      </template>
      <template #foot>
        <b-button
          type="is-text"
          @click="$router.push({
            name: 'training',
            params: {uuid: $route.params.uuid}
          })"
        >
          Fermer
        </b-button>
      </template>
    </AppCardModal>
  </b-modal>
</template>
